@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter/Inter-Regular.ttf') format('truetype'),
    url('./assets/fonts/Inter/Inter-Regular.woff2') format('woff2'),
    url('./assets/fonts/Inter/Inter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter/Inter-Medium.ttf') format('truetype'),
    url('./assets/fonts/Inter/Inter-Medium.woff2') format('woff2'),
    url('./assets/fonts/Inter/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype'),
    url('./assets/fonts/Inter/Inter-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/Inter/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter/Inter-ExtraBold.ttf') format('truetype'),
    url('./assets/fonts/Inter/Inter-ExtraBold.woff2') format('woff2'),
    url('./assets/fonts/Inter/Inter-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  font-variant: normal;
}

html,
body,
#root {
  height: 100vh;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-marker-icon {
  height: auto !important;
  width: auto !important;
}
