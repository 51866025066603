.react-datepicker__input-container {
  @apply flex items-center;
}

.react-datepicker__close-icon {
  @apply static ml-1.5 block p-0;
}

.react-datepicker__close-icon:after {
  @apply bg-gray-300 w-4 h-4 p-0 block align-middle leading-[15px];
}

.react-datepicker__close-icon:hover .react-datepicker__close-icon:after {
  @apply bg-gray-600;
}

.f5-calendar {
  @apply border-none rounded-lg shadow-lg p-4 z-[2000];
}

.f5-calendar .react-datepicker__triangle {
  @apply hidden;
}

.f5-calendar .react-datepicker__header {
  @apply bg-white border-none pt-0;
}

.f5-calendar .react-datepicker__day-names {
  @apply mt-2;
}

.f5-calendar .react-datepicker__day-name {
  @apply w-9 h-6 font-medium text-sm text-gray-500 leading-6;
}

.f5-calendar .react-datepicker__day {
  @apply hover:bg-gray-100 rounded-lg text-gray-900 text-sm font-semibold leading-9 w-9 h-9;
}

.f5-calendar .react-datepicker__day--in-range {
  @apply bg-indigo-700 text-white;
}

.f5-calendar .react-datepicker__day--in-range {
  @apply bg-indigo-700 text-white hover:bg-indigo-500 hover:text-white;
}

.f5-calendar .react-datepicker__day--in-selecting-range,
.f5-calendar .react-datepicker__day--keyboard-selected {
  @apply bg-blue-200;
}

.f5-calendar
  .react-datepicker__day--keyboard-selected.react-datepicker__day--in-range {
  @apply text-gray-900 hover:text-white;
}

.f5-calendar .react-datepicker__day--outside-month {
  @apply text-gray-500;
}

.f5-calendar .react-datepicker__month {
  @apply m-0;
}

.f5-calendar .react-datepicker__navigation {
  @apply top-[17px];
}

.f5-calendar .react-datepicker__navigation--previous {
  @apply left-[14px];
}

.f5-calendar .react-datepicker__navigation--next {
  @apply right-[14px];
}

.react-datepicker-popper {
  @apply z-[2000];
}

.f5-input input {
  @apply py-1;
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    @apply bg-transparent;
    border-radius: 10px;
    opacity: 0;
  }

  &:hover::-webkit-scrollbar-thumb {
    opacity: 1;
    @apply bg-gray-300;
  }
}
