.station-stats th,
.station-stats td {
  @apply text-sm;
}

.station-stats th {
  @apply pb-0;
}

.station-stats td {
  @apply font-medium;
}
